import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const Activate = () => {
  const { token } = useParams(); // Get the token from the URL
  const navigate = useNavigate();
  const [activationStatus, setActivationStatus] = useState(null); // Tracks if activation was successful

  useEffect(() => {
    // Function to verify the token
    const verifyEmail = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/users/verify/${token}`);
        if (res.data.success) {
          // On successful activation, redirect to login with success message
          navigate('/login', { state: { successMessage: 'Activation successful! You can now log in.' } });
        }
      } catch (err) {
        setActivationStatus('error'); // If the token is invalid or expired, show error
      }
    };

    verifyEmail();
  }, [token, navigate]);

  return (
    <div className="activation-page">
      {activationStatus === 'error' ? (
        <p className="error-message">Activation failed. The token may be invalid or expired.</p>
      ) : (
        <p>Activating your account, please wait...</p>
      )}
    </div>
  );
};

export default Activate;
