import React, { useState, useEffect } from 'react';
import growCareerImg from '../../images/technology.jpg';  // Import image paths
import consultingImg from '../../images/consulting.jpg';
import operationsImg from '../../images/operations.jpg';
import aiAnalyticsImg from '../../images/ai.jpg';
import cyberSecurityImg from '../../images/cybersecurity.jpg';
import { FaPause, FaPlay, FaArrowLeft, FaArrowRight } from 'react-icons/fa6';

const slides = [
  {
    image: growCareerImg,
    title: 'Grow Your Career',
    description: 'Opportunities for learning and growth in technology, helping you advance your career in a dynamic environment.'
  },
  {
    image: consultingImg,
    title: 'Consulting',
    description: 'Collaborating with businesses to implement solutions that enhance performance and operational efficiency.'
  },
  {
    image: operationsImg,
    title: 'Operations',
    description: 'Optimizing business processes through supply chain management and operational excellence.'
  },
  {
    image: aiAnalyticsImg,
    title: 'AI and Analytics',
    description: 'Leverage data and AI to make informed decisions and drive business innovation.'
  },
  {
    image: cyberSecurityImg,
    title: 'Cyber Security',
    description: 'Ensuring the security of digital assets through cutting-edge cybersecurity solutions and risk management.'
  }
];

const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying) {
      const slideInterval = setInterval(goToNextSlide, 9000); // Slide changes every 3 seconds
      return () => clearInterval(slideInterval);
    }
  }, [isPlaying]);

  return (
    <div className="slideshow-container">
      <div className="slideshow-image">
        <img src={slides[currentSlide].image} alt={slides[currentSlide].title} />
      </div>
      <div className="slideshow-content">
        <h2>{slides[currentSlide].title}</h2>
        <p>{slides[currentSlide].description}</p>
      </div>
      <div className="slideshow-controls">
        <button onClick={togglePlayPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
      </div>
      <div className="slideshow-arrows">
        <button onClick={goToPreviousSlide}><FaArrowLeft /></button>
        <button onClick={goToNextSlide}><FaArrowRight /></button>
      </div>
    </div>
  );
};

export default Slideshow;
