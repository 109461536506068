// UserProfileView.js
import React from 'react';
import UserDashboard from '../User/UserDashboard';

const UserProfileView = () => {
  return (
    <UserDashboard readOnly={true} />  // Pass readOnly as true for admin viewing
  );
};

export default UserProfileView;
