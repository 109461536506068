import React from 'react';
import AdminDashboard from '../Components/Admin/AdminDashboard';
import UserDashboard from '../Components/User/UserDashboard';

const Dashboard = () => {
  const userRole = localStorage.getItem('userRole');

  if (userRole === 'admin') {
    return <AdminDashboard />;
  } else {
    return <UserDashboard />;
  }
};

export default Dashboard;
