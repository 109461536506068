import React from 'react';
import JobTile from './JobTile';

const JobList = ({ jobs }) => {
  return (
    <div className="job-list">
      {jobs.length > 0 ? (
        jobs.map(job => (
          <JobTile key={job._id} job={job} />
        ))
      ) : (
        <p>No jobs found</p>
      )}
    </div>
  );
};

export default JobList;
