import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram, FaGlobe, FaPlus, FaTrash } from 'react-icons/fa';
import { IoCamera, IoPersonCircleOutline } from 'react-icons/io5';
import { IoMdDocument } from 'react-icons/io';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { LoadingContext } from '../Contexts/LoadingContext';
import { useParams } from 'react-router-dom';

const UserDashboard = ({readOnly = false}) => {
  const {userId} = useParams();
  const {setLoading} = useContext(LoadingContext);
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    mobile: '',
    bio: '',
    profilePicture: {
      fileId: '',
      fileName: '',
      url: ''
    },
    socialLinks: [],
    address: {
      street: '',
      city: '',
      state: '',
      postalCode: '',
      country: ''
    },
    applications: [],
    resume: {
      fileId: '',
      fileName: '',
      url: ''
    }
  });

  const [editMode, setEditMode] = useState(false);
  const [profileCompleteness, setProfileCompleteness] = useState(0);
  const [showGenderPopup, setShowGenderPopup] = useState(false);
  const [selectedGender, setSelectedGender] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('authToken');
      try {
        setLoading(true);
        const apiUrl = readOnly 
            ? `${process.env.REACT_APP_BACKEND_URI}/profiles/admin/view-user/${userId}`
            : `${process.env.REACT_APP_BACKEND_URI}/profiles/me`;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data.data.profile;
        const applications = response.data.data.applications;
        setProfile({
          name: data.user.name || '',
          email: data.user.email || '',
          mobile: data.mobile || '',
          bio: data.bio || '',
          socialLinks: data.socialLinks || [],
          address: data.address || {
            street: '',
            city: '',
            state: '',
            postalCode: '',
            country: ''
          },
          resume: data.resume || {
            fileId: '',
            fileName: '',
            url: ''
          },
          profilePicture: data.profilePicture || {
            fileId: '',
            fileName: '',
            url: ''
          },
          applications: applications || []
        });
        if (!data.user.gender || data.user.gender === "") {
          setShowGenderPopup(true);
        }
        calculateProfileCompleteness(data); // Calculate completeness
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profile', error);
        setLoading(false);
      } finally{
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userId,readOnly, setLoading]);

  const handleGenderSubmit = async () => {
    if (selectedGender) {
      const token = localStorage.getItem('authToken');
      try {
        await axios.put(
          `${process.env.REACT_APP_BACKEND_URI}/profiles/update-gender`,
          { gender: selectedGender },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setShowGenderPopup(false);
        setProfile({ ...profile, gender: selectedGender });
      } catch (error) {
        console.error('Error updating gender', error);
      }
    }
  };

  // Automatically upload profile picture
  const handleProfilePictureChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    const token = localStorage.getItem('authToken');

    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/profiles/upload-profile-picture`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setProfile({ ...profile, profilePicture: response.data.data.profilePicture });
      alert('Profile picture uploaded successfully');
    } catch (error) {
      console.error('Error uploading profile picture:', error);
    } finally{
      setLoading(false);
    }
  };

  // Automatically upload resume
  const handleResumeChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    const token = localStorage.getItem('authToken');

    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/profiles/upload-resume`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setProfile({ ...profile, resume: response.data.data.resume });
      alert('Resume uploaded successfully');
    } catch (error) {
      console.error('Error uploading resume:', error);
    } finally{
      setLoading(false);
    }
  };

  const calculateProfileCompleteness = (data) => {
    let filledFields = 0;
    const totalFields = 6;

    if (data.user.name) filledFields++;
    if (data.user.email) filledFields++;
    if (data.mobile) filledFields++;
    if (data.bio) filledFields++;
    if (data.socialLinks && data.socialLinks.length > 0) filledFields++;

    setProfileCompleteness((filledFields / totalFields) * 100);
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleAddSocialLink = () => {
    setProfile({
      ...profile,
      socialLinks: [...profile.socialLinks, { url: '', platform: 'globe' }]
    });
  };

  const handleRemoveSocialLink = (index) => {
    const newSocialLinks = [...profile.socialLinks];
    newSocialLinks.splice(index, 1);
    setProfile({ ...profile, socialLinks: newSocialLinks });
  };

  const handleSocialLinkChange = (index, value) => {
    const newSocialLinks = [...profile.socialLinks];
    newSocialLinks[index].url = value;
    newSocialLinks[index].platform = detectSocialPlatform(value);
    setProfile({ ...profile, socialLinks: newSocialLinks });
  };

  const detectSocialPlatform = (url) => {
    if (url.includes('linkedin')) return 'linkedin';
    if (url.includes('x.com')) return 'twitter';
    if (url.includes('facebook')) return 'facebook';
    if (url.includes('instagram')) return 'instagram';
    return 'globe';
  };

  const obfuscateURL = (url) => {
    try {
      const urlObj = new URL(url);
      return `${urlObj.pathname}`;
    } catch (error) {
      return url;
    }
  };

  const isValidURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + 
      '((([a-zA-Z0-9\\-]+\\.)+[a-zA-Z]{2,})|' + 
      'localhost|' + 
      '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})' + 
      '(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*' + 
      '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + 
      '(\\#[-a-zA-Z0-9_]*)?$');
    return pattern.test(str);
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      address: { ...profile.address, [name]: value }
    });
  };

  const handleSave = async () => {
    const incompleteLinks = profile.socialLinks.some(link => !isValidURL(link.url));
    if (incompleteLinks) {
      alert('Please fill out valid URLs or remove incomplete links before saving.');
      return;
    }

    const token = localStorage.getItem('authToken');
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URI}/profiles/me`, profile, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEditMode(false);
      calculateProfileCompleteness(profile);
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };


  return (
    <div className={`user-dashboard ${editMode ? 'dark-backdrop' : ''}`}>
      {!readOnly && showGenderPopup && (
        <div className="gender-popup">
          <h3>Please Select Your Gender</h3>
          <select onChange={(e) => setSelectedGender(e.target.value)} value={selectedGender}>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
            <option value="rather not say">Rather not say</option>
          </select>
          <button onClick={handleGenderSubmit}>Submit</button>
        </div>
      )}
      <div className="profile-header">
        <div className="profile-image" onClick={() => document.getElementById('profile-upload').click()}>
          {profile.profilePicture.url ? (
            <img src={profile.profilePicture.url} alt="Profile" />
          ) : (
            <IoPersonCircleOutline className="default-profile-icon" />
          )}
          {!readOnly && <IoCamera className="camera-icon" />}
          <input
            type="file"
            id="profile-upload"
            style={{ display: 'none' }}
            onChange={handleProfilePictureChange}
            accept="image/*"
          />
        </div>

        <div className="profile-details">
          {editMode ? (
            <>
              <input
                type="text"
                name="name"
                value={profile.name}
                onChange={(e) => setProfile({ ...profile, name: e.target.value })}
                placeholder="Name"
              />
              <input
                type="text"
                name="mobile"
                value={profile.mobile}
                onChange={(e) => setProfile({ ...profile, mobile: e.target.value })}
                placeholder="Mobile"
              />
              <div className="address-inputs">
                <input type="text" name="street" value={profile.address.street} onChange={handleAddressChange} placeholder="Street" />
                <input type="text" name="city" value={profile.address.city} onChange={handleAddressChange} placeholder="City" />
                <input type="text" name="state" value={profile.address.state} onChange={handleAddressChange} placeholder="State" />
                <input type="text" name="postalCode" value={profile.address.postalCode} onChange={handleAddressChange} placeholder="Postal Code" />
                <input type="text" name="country" value={profile.address.country} onChange={handleAddressChange} placeholder="Country" />
              </div>
            </>
          ) : (
            <>
              <h2>{profile.name}</h2>
              <p>{profile.email}</p>
              <p>{profile.mobile}</p>
            </>
          )}
        </div>
          {!readOnly ? (
        <div className="edit-profile">
          <button onClick={editMode ? handleSave : handleEditToggle}>
            {editMode ? 'Save' : 'Edit Profile'}
          </button>
        </div>
        ):(
          <p></p>
        )}

        <div className="profile-completeness">
          <CircularProgressbar
            value={profileCompleteness}
            text={`${Math.round(profileCompleteness)}%`}
            styles={buildStyles({
              textColor: '#c52422',
              pathColor: '#c52422',
              trailColor: '#d6d6d6',
            })}
          />
          <p>Profile Completeness</p>
        </div>
      </div>

      <div className="profile-body">
        <div className="profile-left">
          <h3>Bio</h3>
          {editMode ? (
            <textarea name="bio" className='bio' value={profile.bio} onChange={(e) => setProfile({ ...profile, bio: e.target.value })} />
          ) : (
            <p className='bio'>{profile.bio || 'No bio added'}</p>
          )}

          <h3>Resume</h3>
          {profile.resume.url ? (
            <ul>
              <li>
                <p><IoMdDocument /> : <a href={profile.resume.url} target='_blank' rel='noreferrer'>Resume</a></p>
              </li>
            </ul>
          ) : (
            <p>No resume uploaded</p>
          )}

          {editMode && (
            <>
              <input
                type="file"
                id="resume-upload"
                style={{ display: 'none' }}
                onChange={handleResumeChange}
                accept=".pdf,.doc,.docx"
              />
              <button onClick={() => document.getElementById('resume-upload').click()} className="upload-button">Upload Resume</button>
            </>
          )}

          <h3>Social Links</h3>
          <ul>
            {profile.socialLinks.length > 0 ? (
              profile.socialLinks.map((link, index) => (
                <li key={index}>
                  {link.platform === 'linkedin' && <FaLinkedin size={30} />}
                  {link.platform === 'twitter' && <FaTwitter size={30} />}
                  {link.platform === 'facebook' && <FaFacebook size={30} />}
                  {link.platform === 'instagram' && <FaInstagram size={30} />}
                  {link.platform === 'globe' && <FaGlobe size={30} />}
                  {editMode ? (
                    <>
                      <input
                        type="text"
                        value={link.url}
                        onChange={(e) => handleSocialLinkChange(index, e.target.value)}
                        placeholder="Enter social link"
                      />
                      <FaTrash className="delete-icon" onClick={() => handleRemoveSocialLink(index)} />
                    </>
                  ) : (
                    <a href={link.url}>{obfuscateURL(link.url)}</a>
                  )}
                </li>
              ))
            ) : (
              <p>No social links added</p>
            )}
          </ul>
          <p className='note'>Please Try to add more Information about you using edit option to get to know you better while recruiting, also don't forget to add you profile photo in the dashboard.</p>
          {editMode && <FaPlus className="add-icon" onClick={handleAddSocialLink} />}
        </div>

        <div className="profile-right">
          <h3>Applied Jobs</h3>
          {profile.applications.length > 0 ? (
            <ul>
              {profile.applications.map(app => (
                <li key={app.id} >
                  <div className="application-title">{app.job.title}</div>
                  <div className="application-status">{app.status}</div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No job applications found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
