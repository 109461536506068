import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from '../Contexts/LoadingContext';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const {setLoading} = useContext(LoadingContext);

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/users/forgot-password`, {
        email,
        frontendURL: window.location.origin, // Pass the frontend URL to construct reset link
      });
      if(res.status === 200){
      navigate('/login',{state:{successMessage:'An Email is Sent to Reset Your Password.'}});
      }else{
        setMessage('Error Sending Password Reset Link.')
      }
    } catch (err) {
      setMessage('Error sending password reset link');
    } finally{
      setLoading(false);
    }
  };

  return (
    <div className="auth-form">
      <h2>Forgot Password</h2>
      <form onSubmit={onSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className='btn'>Send Reset Link</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ForgotPassword;
