import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ViewPostedJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/jobs/alljobs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setJobs(res.data.data);
      } catch (err) {
        setError('Failed to fetch jobs');
      } finally{
        
      }
    };

    fetchJobs();
  }, []);

  return (
    <div className="view-jobs-container">
      <h2>Posted Jobs</h2>
      {error && <p className="error">{error}</p>}

      <table>
        <thead>
          <tr>
            <th>Job Title</th>
            <th>Posted By</th>
            <th>Posted Date</th>
            <th>Number of Applicants</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {jobs.length > 0 ? (
            jobs.map(job => (
              <tr key={job._id}>
                <td>{job.title}</td>
                <td>{job.postedBy.name}</td>
                <td>{new Date(job.postedDate).toLocaleDateString()}</td>
                <td>{job.numberOfApplicants}</td>
                <td>
                  <Link to={`/admin/job-applications/${job._id}`} className="view-applicants-btn">View Applicants</Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: 'center' }}>No jobs found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ViewPostedJobs;
