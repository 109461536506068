import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../Contexts/AuthContext';
import companyLogo from '../../assets/K E R N N.png';

const Header = () => {
  const { authenticated, logout } = useContext(AuthContext); // Access auth context
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout(); // Call logout from context
    navigate('/');
  };

  return (
    <header>
      <div className="container">
        <nav>
          <div className="logo-container">
            <img src={companyLogo} alt="Kernn Automations Logo" />
            <div className="logo-text">ernn Automations Careers</div>
          </div>

          {/* Hamburger menu for mobile */}
          <div className="hamburger" onClick={toggleMenu}>
            <div></div>
            <div></div>
            <div></div>
          </div>

          {/* Navigation links */}
          <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/jobs">Jobs</Link></li>
            {authenticated ? (
              <>
                <li><Link to="/dashboard">Dashboard</Link></li>
                <li><button onClick={handleLogout} className="logout-button">Logout</button></li>
              </>
            ) : (
              <li><Link to="/login">Login</Link></li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
