import React from 'react';
import { useNavigate } from 'react-router-dom';

const JobTile = ({ job }) => {
  const navigate = useNavigate();

  const handleJobClick = () => {
    navigate(`/jobs/${job._id}`);
  };

  return (
    <div className="job-tile" onClick={handleJobClick}>
      <h3>{job.title}</h3>
      <p>{job.location}</p>
      <p>{job.department}</p>
      <p>Skills Required: {job.skills.join(', ')}</p>
    </div>
  );
};

export default JobTile;
