import React, { useState, useEffect } from 'react';
import JobList from '../Components/Jobs/JobList';
import axios from 'axios';

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);

  useEffect(() => {
    // Fetch jobs from backend
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/jobs/alljobs`);
        console.log(response.data.data);
        setJobs(response.data.data);
        setFilteredJobs(response.data.data);
        // Extract unique departments from jobs
        const uniqueDepartments = [...new Set(response.data.map(job => job.department))];
        setDepartments(uniqueDepartments);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    const query = e.target.value.toLowerCase();
    const filtered = jobs.filter(job => 
      job.title.toLowerCase().includes(query) || 
      job.skills.some(skill => skill.toLowerCase().includes(query))
    );
    setFilteredJobs(filtered);
  };

  const toggleFilter = () => {
    setFilterVisible(!filterVisible);
  };

  const handleDepartmentFilter = (dept) => {
    const updatedSelection = selectedDepartments.includes(dept)
      ? selectedDepartments.filter(d => d !== dept)
      : [...selectedDepartments, dept];
    setSelectedDepartments(updatedSelection);

    // Filter jobs by department
    if (updatedSelection.length === 0) {
      setFilteredJobs(jobs);
    } else {
      setFilteredJobs(jobs.filter(job => updatedSelection.includes(job.department)));
    }
  };

  return (
    <div className="jobs-page">
      <div className="container">
        <h1>Open Positions</h1>
        <p>Join our team and help us build the future of technology</p>

        {/* Search and Filter */}
        <div className="search-filter-section">
          <input
            type="text"
            placeholder="Search by job title or skills"
            value={searchQuery}
            onChange={handleSearch}
            className="search-bar"
          />
          <button onClick={toggleFilter} className="filter-btn">Filter by Department</button>
        </div>

        {/* Filter Options (Departments) */}
        {filterVisible && (
          <div className="filter-popup">
            {departments.map((dept, idx) => (
              <label key={idx} className="filter-checkbox">
                <input
                  type="checkbox"
                  checked={selectedDepartments.includes(dept)}
                  onChange={() => handleDepartmentFilter(dept)}
                />
                {dept}
              </label>
            ))}
          </div>
        )}

        {/* Job List */}
        <JobList jobs={filteredJobs} />
      </div>
    </div>
  );
};

export default Jobs;
