// src/pages/AdminDashboard.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const AdminDashboard = () => {
  const [ucount, setUcount] = useState();
  const [jcount, setJcount] = useState();
  const [acount, setAcount] = useState();

  useEffect(()=> {
    const fetchAdminData = async () => {
      try{
        const token = localStorage.getItem('authToken');
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/users/admin-data`,{
          headers:{
            'Authorization':`Bearer ${token}`
          }
        }
        );
        setUcount(res.data.users_count);
        setJcount(res.data.jobs_count);
        setAcount(res.data.applications_count);
      }catch(err){
        console.error(err);
      }
    }

    fetchAdminData();
  });

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <div className="dashboard-options">
        <Link to="/admin/create-admin">Create Admin</Link>
        <Link to="/admin/view-users">View Registered Users</Link>
        <Link to="/admin/job-applications">View Job Applications</Link>
        <Link to="/admin/create-job">Create Job</Link>
      </div>
      <div className='admin-data'>
        <div className='users'>
          <h4>Users</h4>
          {ucount}
        </div>
        <div className='jobs'>
          <h4>Jobs</h4>
          {jcount}
        </div>
        <div className='applications'>
          <h4>Applications</h4>
          {acount}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
