import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoEnterOutline } from 'react-icons/io5';
import { FaKey } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ViewUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/users/all-users`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUsers(res.data.data);
        setFilteredUsers(res.data.data);
      } catch (err) {
        setError('Failed to fetch users');
      }
    };

    fetchUsers();
  }, []);

  // Handle search input
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);

    if (e.target.value === '') {
      setFilteredUsers(users); // Reset the list when search input is cleared
    } else {
      const query = e.target.value.toLowerCase();
      const filtered = users.filter(user => 
        user.name.toLowerCase().includes(query) || 
        user.email.toLowerCase().includes(query)
      );
      setFilteredUsers(filtered);
    }
  };

  // Handle reset password
  const handleResetPassword = async (email) => {
    try {
      const token = localStorage.getItem('authToken');
      await axios.post(`${process.env.REACT_APP_BACKEND_URI}/users/admin-reset-password`, { email }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Password reset email sent');
    } catch (err) {
      alert('Failed to send password reset email');
    }
  };

  // Handle viewing user profile


  return (
    <div className="view-users-container">
      <h2>Registered Users</h2>
      {error && <p className="error">{error}</p>}

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by name or email"
        value={searchQuery}
        onChange={handleSearch}
        className="search-bar"
      />

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Gender</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.length > 0 ? (
            filteredUsers.map(user => (
              <tr key={user._id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.gender}</td>
                <td className='actions'> 
                  <FaKey 
                    onClick={() => handleResetPassword(user.email)} 
                    title="Reset Password" 
                    style={{ cursor: 'pointer', marginRight: '10px', color: '#c52422' }}
                  />
                  <Link to={`/admin/view-users/${user._id}`} style={{cursor: 'pointer', marginRight: '10px', color: '#c52422' }}><IoEnterOutline /></Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" style={{ textAlign: 'center' }}>No users found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ViewUsers;
