import React, { useContext,useState } from 'react';
import axios from 'axios';
import { Link,useLocation, useNavigate } from 'react-router-dom';
import logoimg from '../../assets/undraw_secure_login_pdn4.png';
import { AuthContext } from '../Contexts/AuthContext';
import { LoadingContext } from '../Contexts/LoadingContext';

const Login = () => {
  const { login } = useContext(AuthContext);
  const {setLoading} = useContext(LoadingContext);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const location = useLocation();
  const successMessage = location.state?.successMessage;
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const { email, password } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  /*const validate = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = 'Email is required';
    } else {
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(email)) newErrors.email = 'Email is invalid';
    }
    if (!password) newErrors.password = 'Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };*/

  const onSubmit = async e => {
    e.preventDefault();
    setErrors({});

    try {
      setLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/users/login`, { email, password });
      const { token, role } = res.data;

      // Call the login function to update context and localStorage
      login(token, role);

      // Redirect to dashboard or other page after login
      navigate('/dashboard');
    } catch (err) {
      setErrors({ server: err.response.data.error });
    } finally{
      setLoading(false);
    }
  };

  return (
    <div className="auth-form">
      <div className='logo-img'>
        <img src={logoimg} alt='login-img'/>
      </div>
      <h2>Login to Your Account</h2>
      {errors.server && <p className="error">{errors.server}</p>}
      {successMessage && <p className='success-message'>{successMessage}</p>}
      <form onSubmit={onSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={email}
          onChange={onChange}
        />
        {errors.email && <p className="error">{errors.email}</p>}
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={onChange}
        />
        {errors.password && <p className="error">{errors.password}</p>}
        <button type="submit" className="btn">Login</button>
      </form>
      <p className="forgot-password">
        <Link to="/forgot-password">Forgot your password?</Link>
      </p>
      <p>
        Don't have an account? <a href="/signup">Sign up here</a>
      </p>
    </div>
  );
};

export default Login;
