
import React from 'react';
import { Link } from 'react-router-dom';
import notfound from '../assets/undraw_Page_not_found_re_e9o6.png';


const NotFound = () => {
  return (
    <div className='not-found'>
        <div className='none-img'>
        <img src={notfound} alt='404 - Not Found' />
        </div>
        <div className='not-found-des'>
            <p>Looks Like You Found a Deadend. <Link to="/">Click Here to Go Home</Link></p>
        </div>
    </div>
  );
};

export default NotFound;
