import React from 'react';
import { Link } from 'react-router-dom';
import Slideshow from '../Components/Structure/Slideshow';

const Home = () => {
  return (
    <div className="home">
      <section className="hero">
        <div className="container">
          <h1>Automating the Future with ERP Solutions</h1>
          <p>Kernn Automations provides cutting-edge ERP solutions, automating business environments across industries.</p>
          <Link to="/jobs" className="btn">View Open Positions</Link>
        </div>
      </section>
      
      <section className="about">
        <Slideshow />
      </section>
      
      <section className="culture">
        <div className="container">
          <h2>Work Culture</h2>
          <div className="benefits">
            <div className="benefit-item">
              <h3>Fast-Paced Environment</h3>
              <p>We thrive in a dynamic startup environment with plenty of opportunities for learning and growth.</p>
            </div>
            <div className="benefit-item">
              <h3>Flexible Working Hours</h3>
              <p>Work remotely or at our office—our flexible schedule helps balance life and work.</p>
            </div>
            <div className="benefit-item">
              <h3>Employee Benefits</h3>
              <p>Enjoy a range of employee perks, including healthcare, wellness programs, and professional development opportunities.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
