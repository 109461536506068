import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const AuthContext = createContext();

// Provide the context globally
export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    // Check if the user is authenticated by checking the token
    const token = localStorage.getItem('authToken');
    const role = localStorage.getItem('userRole');
    if (token) {
      setAuthenticated(true);
      setUserRole(role);
    } else {
      setAuthenticated(false);
      setUserRole(null);
    }
  }, []);

  const login = (token, role) => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('userRole', role);
    setAuthenticated(true);
    setUserRole(role);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');
    setAuthenticated(false);
    setUserRole(null);
  };

  return (
    <AuthContext.Provider value={{ authenticated, userRole, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
