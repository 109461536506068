import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ApplyForm from './ApplyForm';

const JobDetails = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [userApplied, setUserApplied] = useState(false); // Track if user has applied
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleApply = () => {
    if (!isLoggedIn) {
      navigate('/login', { state: { message: 'Please log in to apply for this job.' } });
    }
  };

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (token) {
          setIsLoggedIn(true);
          const jobResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/jobs/alljobs/${jobId}`);
          setJob(jobResponse.data.data);

          // Check if the user has already applied
          const applicationResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/jobs/alljobs/${jobId}/application-status`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUserApplied(applicationResponse.data.applied); // Update state based on response
        } else {
          // Fetch job details without checking application status if the user isn't logged in
          const jobResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/jobs/alljobs/${jobId}`);
          setJob(jobResponse.data.data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching job details:', error);
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="job-details-page">
      {job && (
        <div className="job-details">
          <h1>{job.title}</h1>
          <div className='attrs'>
          <div className='responsibilities'>
          <h3>Responsibilities:</h3>
          <ul>
            {job.responsibilities.map((res, index) => <li key={index}>{res}</li>)}
          </ul>
          </div>
          <div className='skills'>
          <h3>Skills Required:</h3>
          <ul>
            {job.skills.map((skill, index) => <li key={index}>{skill}</li>)}
          </ul>
          </div>
          <div className='qualifications'>
          <h3>Qualifications:</h3>
          <ul>
            {job.qualifications.map((qual, index) => <li key={index}>{qual}</li>)}
          </ul>
          </div>
          </div>
          <div className='job-description' dangerouslySetInnerHTML={{__html: job.description}} />

          {/* Check if the user has already applied */}
          {isLoggedIn && userApplied ? (
            <div className="applied-message">
              <p>You have already applied for this job. <Link to="/dashboard">View status here</Link>.</p>
            </div>
          ) : isLoggedIn ? (
            <div className="apply-section">
              <ApplyForm jobId={jobId} questions={job.questions} />
            </div>
          ):(
            <div className="apply-button-section">
              <button className="apply-button" onClick={handleApply}>
                Apply Now
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default JobDetails;
