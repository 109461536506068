import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEye, FaEyeSlash, FaKey } from 'react-icons/fa'; // Import icons

const CreateAdmin = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    showPassword: false,
  });
  const [admins, setAdmins] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { name, email, password, showPassword } = formData;
  const token = localStorage.getItem('authToken'); // Get token from localStorage

  // Fetch all admins except the current one
  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/users/admins`, {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the headers
          },
        });
        setAdmins(res.data.data);
      } catch (err) {
        setError('Failed to fetch admins');
      }
    };
    fetchAdmins();
  }, [token]);

  // Handle form input changes
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setFormData({ ...formData, showPassword: !showPassword });
  };

  // Handle form submission to create a new admin
  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/users/create-admin`, 
        { name, email, password },
        {
          headers: {
            Authorization: `Bearer ${token}` // Pass the token in the headers
          }
        }
      );
      if(res.status === 200){
      setSuccess('Admin created successfully');
      setFormData({ name: '', email: '', password: '', showPassword: false });
      const adminRes = await axios.get(`${process.env.REACT_APP_BACKEND_URI}/users/admins`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setAdmins(adminRes.data.data);
    }
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to create admin');
    }
  };

  // Function to handle reset password
  const handleResetPassword = async (email) => {
    const frontendURL = window.location.origin;
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URI}/users/admin-reset-password`, { email,frontendURL }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body:{
          "email":email,
          "frontendURL":frontendURL
        }
      });
      setSuccess(`Password reset email sent to ${email}`);
    } catch (err) {
      setError('Failed to send password reset email');
    }
  };

  return (
    <div className="create-admin-container">
      <div className="form-container">
        <h2>Create Admin</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={onSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={name}
            onChange={onChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={email}
            onChange={onChange}
            required
          />
          <div className="password-field">
            <input
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              value={password}
              onChange={onChange}
              required
            />
            <span onClick={togglePasswordVisibility} className="toggle-password">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <button type="submit" className="btn">Create Admin</button>
        </form>
      </div>

      <div className="table-container">
        <h3>Current Admins</h3>
        {admins.length === 0 ? (
          <p>No other admins found.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {admins.map((admin) => (
                <tr key={admin._id}>
                  <td>{admin.name}</td>
                  <td>{admin.email}</td>
                  <td>
                    <FaKey 
                      onClick={() => handleResetPassword(admin.email)} 
                      style={{ cursor: 'pointer', color: '#c52422' }}
                      title="Reset Password"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CreateAdmin;
