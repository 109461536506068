// PostJob.js

import React, { useState } from 'react';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PostJob = () => {
  const [job, setJob] = useState({
    title: '',
    description: '',
    responsibilities: [],
    skills: [],
    qualifications: [],
    experience: '',
    jobType: '',
    location: '',
    salary: {
      min: '',
      max: '',
      currency: 'USD',
    },
    department: '',
    applicationDeadline: '',
    numberOfOpenings: 1,
    status: 'Open',
    questions: [],
  });

  const [questionInput, setQuestionInput] = useState('');
  const [responsibilityInput, setResponsibilityInput] = useState('');
  const [skillInput, setSkillInput] = useState('');
  const [qualificationInput, setQualificationInput] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('salary.')) {
      setJob({
        ...job,
        salary: { ...job.salary, [name.split('.')[1]]: value },
      });
    } else {
      setJob({ ...job, [name]: value });
    }
  };

  // Add custom questions, responsibilities, skills, and qualifications
  const handleAddResponsibility = () => {
    if (responsibilityInput) {
      setJob({
        ...job,
        responsibilities: [...job.responsibilities, responsibilityInput],
      });
      setResponsibilityInput('');
    }
  };

  const handleAddSkill = () => {
    if (skillInput) {
      setJob({
        ...job,
        skills: [...job.skills, skillInput],
      });
      setSkillInput('');
    }
  };

  const handleAddQualification = () => {
    if (qualificationInput) {
      setJob({
        ...job,
        qualifications: [...job.qualifications, qualificationInput],
      });
      setQualificationInput('');
    }
  };

  const handleAddQuestion = () => {
    if (questionInput) {
      setJob({
        ...job,
        questions: [...job.questions, questionInput],
      });
      setQuestionInput('');
    }
  };

  // Remove an item from array (responsibility, skill, qualification, question)
  const handleRemoveItem = (arrayName, index) => {
    const updatedArray = [...job[arrayName]];
    updatedArray.splice(index, 1);
    setJob({ ...job, [arrayName]: updatedArray });
  };

  // Submit the job posting
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      await axios.post(`${process.env.REACT_APP_BACKEND_URI}/jobs/post`, job, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Job posted successfully!');
      // Reset the form
      setJob({
        title: '',
        description: '',
        responsibilities: [],
        skills: [],
        qualifications: [],
        experience: '',
        jobType: '',
        location: '',
        salary: {
          min: '',
          max: '',
          currency: 'USD',
        },
        department: '',
        applicationDeadline: '',
        numberOfOpenings: 1,
        status: 'Open',
        questions: [],
      });
    } catch (error) {
      console.error('Error posting job:', error);
      alert('Failed to post job.');
    }
  };


  return (
    <div className="post-job-form">
      <h2>Post a New Job</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Job Title <span className="required">*</span></label>
          <input
            type="text"
            name="title"
            value={job.title}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Description <span className="required">*</span></label>
          <CKEditor
            editor={ClassicEditor}
            data={job.description}
            onChange={(event,editor) =>{
              const data = editor.getData();
              setJob({...job, description:data});
            }}
          />
        </div>

        <div className="form-group">
          <label>Responsibilities <span className="required">*</span></label>
          <ul>
            {job.responsibilities.map((responsibility, index) => (
              <li key={index}>
                {responsibility}
                <button type="button" onClick={() => handleRemoveItem('responsibilities', index)}>Remove</button>
              </li>
            ))}
          </ul>
          <input
            type="text"
            value={responsibilityInput}
            onChange={(e) => setResponsibilityInput(e.target.value)}
            placeholder="Add responsibility"
            className='responsibilities'
          />
          <button type="button" onClick={handleAddResponsibility}>Add Responsibility</button>
        </div>

        <div className="form-group">
          <label>Skills <span className="required">*</span></label>
          <ul>
            {job.skills.map((skill, index) => (
              <li key={index}>
                {skill}
                <button type="button" onClick={() => handleRemoveItem('skills', index)}>Remove</button>
              </li>
            ))}
          </ul>
          <input
            type="text"
            value={skillInput}
            onChange={(e) => setSkillInput(e.target.value)}
            placeholder="Add skill"
            className='skills'
          />
          <button type="button" onClick={handleAddSkill}>Add Skill</button>
        </div>

        <div className="form-group">
          <label>Qualifications <span className="required">*</span></label>
          <ul>
            {job.qualifications.map((qualification, index) => (
              <li key={index}>
                {qualification}
                <button type="button" onClick={() => handleRemoveItem('qualifications', index)}>Remove</button>
              </li>
            ))}
          </ul>
          <input
            type="text"
            value={qualificationInput}
            onChange={(e) => setQualificationInput(e.target.value)}
            placeholder="Add qualification"
            className='qualifications'
          />
          <button type="button" onClick={handleAddQualification}>Add Qualification</button>
        </div>

        <div className="form-group">
          <label>Experience <span className="required">*</span></label>
          <input
            type="text"
            name="experience"
            value={job.experience}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Job Type <span className="required">*</span></label>
          <select name="jobType" value={job.jobType} onChange={handleChange} required>
            <option value="">Select Job Type</option>
            <option value="Full-time">Full-time</option>
            <option value="Part-time">Part-time</option>
            <option value="Contract">Contract</option>
            <option value="Internship">Internship</option>
            <option value="Remote">Remote</option>
          </select>
        </div>

        <div className="form-group">
          <label>Location <span className="required">*</span></label>
          <input
            type="text"
            name="location"
            value={job.location}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Salary (Optional)</label>
          <div className="salary-inputs">
            <input
              type="number"
              name="salary.min"
              value={job.salary.min}
              onChange={handleChange}
              placeholder="Min Salary"
            />
            <input
              type="number"
              name="salary.max"
              value={job.salary.max}
              onChange={handleChange}
              placeholder="Max Salary"
            />
            <input
              type="text"
              name="salary.currency"
              value={job.salary.currency}
              onChange={handleChange}
              placeholder="Currency"
            />
          </div>
        </div>

        <div className="form-group">
          <label>Department <span className="required">*</span></label>
          <input
            type="text"
            name="department"
            value={job.department}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Application Deadline</label>
          <input
            type="date"
            name="applicationDeadline"
            value={job.applicationDeadline}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Number of Openings</label>
          <input
            type="number"
            name="numberOfOpenings"
            value={job.numberOfOpenings}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>Custom Questions</label>
          <ul>
            {job.questions.map((question, index) => (
              <li key={index}>
                {question}
                <button type="button" onClick={() => handleRemoveItem('questions', index)}>Remove</button>
              </li>
            ))}
          </ul>
          <input
            type="text"
            value={questionInput}
            onChange={(e) => setQuestionInput(e.target.value)}
            placeholder="Add custom question"
            className='questions'
          />
          <button type="button" onClick={handleAddQuestion}>Add Question</button>
        </div>

        <button className='job-submit' type="submit">Post Job</button>
      </form>
    </div>
  );
};

export default PostJob;
