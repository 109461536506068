import React, { useContext } from 'react';
import './Spinner.css'; // Import the CSS for styling
import { LoadingContext } from '../Contexts/LoadingContext';

const Spinner = () => {

    const { loading } = useContext(LoadingContext);

    if(!loading) return null;


  return (
    <div className="spinner-overlay">
      <div className="spinner"></div>
    </div>
  );
};

export default Spinner;
