import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import signupimg from '../../assets/undraw_undraw_undraw_undraw_sign_up_ln1s_-1-_s4bc_-1-_ee41_(1)_kf4d.png';
import { LoadingContext } from '../Contexts/LoadingContext';

const Signup = () => {
  const {setLoading} = useContext(LoadingContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    gender:''
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const { name, email, password, confirmPassword,gender } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } else {
      const emailRegex = /\S+@\S+\.\S+/;
      if (!emailRegex.test(email)) newErrors.email = 'Email is invalid';
    }
    if (!password) newErrors.password = 'Password is required';
    if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (!validate()) return;

    try {
      setLoading(true);
      const frontendURL = window.location.origin;
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URI}/users/register`, { name, email, password,gender, frontendURL });
      if(res.status === 201){
      navigate('/login',{state:{successMessage: 'Registration Successful! Please check your email to activate account. We Strongly Recommend you to update your profile in the dashboard after logging in. This Helps us to shortlist fast and efficient.'}});
      }
    } catch (err) {
      setErrors({ server: err.response.data.error });
    } finally{
      setLoading(false);
    }
  };

  return (
    <div className="auth-form">
      <div className='logo-img'>
        <img src={signupimg} alt='signup-img'/>
      </div>
      <h2>Create an Account</h2>
      {errors.server && <p className="error">{errors.server}</p>}
      <form onSubmit={onSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          value={name}
          onChange={onChange}
        />
        {errors.name && <p className="error">{errors.name}</p>}
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={email}
          onChange={onChange}
        />
        {errors.email && <p className="error">{errors.email}</p>}
        <select name="gender" value={gender} onChange={onChange}>
          <option value='' disabled>Select</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
          <option value="rather not say">Rather not say</option>
        </select>
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={onChange}
        />
        {errors.password && <p className="error">{errors.password}</p>}
        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={onChange}
        />
        {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
        <button type="submit" className="btn">Sign Up</button>
      </form>
      <p>
        Already have an account? <a href="/login">Login here</a>
      </p>
    </div>
  );
};

export default Signup;
