import React, { useContext, useState } from 'react';
import axios from 'axios';
import { LoadingContext } from '../Contexts/LoadingContext';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ApplyForm = ({ jobId, questions }) => {
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState('');
  const [answers, setAnswers] = useState([]);
  const {setLoading} = useContext(LoadingContext);

  const handleFileChange = (e) => setResume(e.target.files[0]);
  const navigate = useNavigate();
  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('resume', resume);
    formData.append('coverLetter', coverLetter);
    questions.forEach((q, index) => formData.append(`question_${index}`, answers[index]));

    const token = localStorage.getItem('authToken');

    try {
        setLoading(true);
      await axios.post(`${process.env.REACT_APP_BACKEND_URI}/jobs/alljobs/${jobId}/apply`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Application submitted successfully!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error submitting application:', error);
      alert('Failed to submit application.');
    } finally{
        setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="apply-form">
      <label>Resume:</label>
      <input type="file" onChange={handleFileChange} required />

      <label>Cover Letter:</label>
      <CKEditor editor={ClassicEditor} data={coverLetter} onChange={(event,editor) => {
        const data = editor.getData();
        setCoverLetter(data);
      }} />

      {questions && questions.length > 0 && (
        <div className="job-questions">
          {questions.map((q, index) => (
            <div key={index} className="question-field">
              <label>{q}</label>
              <input type="text" value={answers[index] || ''} onChange={(e) => handleAnswerChange(index, e.target.value)} />
            </div>
          ))}
        </div>
      )}

      <button type="submit" className="submit-btn">Submit Application</button>
    </form>
  );
};

export default ApplyForm;
